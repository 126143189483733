import { AddressType } from "../Address";
import { LanguageType } from "../Language";

export type UserOnboardedType = {
  isUserOnboarded: boolean | undefined;
};

export type UserAuthenticated = {
  isAuthenticated?: boolean | null;
};

export type GroupsOfUserType = {
  id: number;
  name: string;
};

// Add new permissions to this, like this: 'VIEW_OWN_USER' | 'VIEW_USER' | '...'
export type PermissionType = "VIEW_OWN_USER" | "ACCESS_BACKOFFICE";
export enum UserTypeEnum {
  FREE = "free",
  PLUS = "plus",
  PRO = "pro",
}

export enum ScreamingUserTypeEnum {
  FREE = "FREE",
  PLUS = "PLUS",
  PRO = "PRO",
}

export type UserType = {
  kind: "CurrentUser";
  id: number | null;
  firstName: string;
  lastName: string;
  email: string;
  gender: "m" | "f" | "o" | null;
  swipointsBalance: number;
  profilePicture: string | null;
  accountCompleted: boolean;
  isFirstLogin: boolean;
  personalEmail: string | null;
  birthDate: Date | null;
  entryDate: Date | null;
  language: LanguageType;
  newsletter: boolean;
  hrNewsletter?: boolean;
  company_id: number;
  cellPhone: string | null;
  cellPhoneVerified: boolean | null;
  landLinePhone: string | null;
  externalId: string | null;
  groups: GroupsOfUserType[];
  address: AddressType | null;
  percentage: number | null;
  endDate: Date | null;
  permissions: { [key in PermissionType]: boolean };
  timezone: string;
  primary_account: boolean;
  nationality: string | null;
  dashboardTutorial: boolean;
  type: UserTypeEnum;
  ability?: any;
  roles?: string[];
  recovery_email: string | null;
  trialEligibility: boolean;
};

export type GetCurrentUserResponseType = {
  id: number;
  email: string;
  first_name: string;
  last_name: string;
  account_completed: boolean;
  personal_email: string | null;
  gender: string | null;
  birth_date: string | null;
  entry_date: string | null;
  language: LanguageType;
  newsletter: boolean;
  hr_newsletter?: boolean;
  companyId: number;
  profile_picture: string | null;
  cell_phone: string | null;
  land_line_phone: string | null;
  external_id: string | null;
  groups: GroupsOfUserType[];
  swipoints_balance: number;
  address: AddressType;
  percentage: number | null;
  end_date: string | null;
  permissions: { [key in PermissionType]: boolean };
  timezone: string;
  primary_account: boolean;
  nationality: string | null;
  is_first_login: boolean;
  dashboard_tutorial: boolean;
  type: UserTypeEnum;
};

export type Actions =
  | "ACCESS"
  | "EDIT"
  | "CREATE"
  | "VIEW"
  | "DELETE"
  | "IMPORT"
  | "INVITE"
  | "VALIDATE"
  | "RESTORE"
  | "REFUND";
export type Resources =
  | "BACKOFFICE"
  | "BLACKLIST"
  | "BRAND"
  | "COMPANY_EMAIL"
  | "COMPANY"
  | "OWN_COMPANY"
  | "COMPANY_SWIPOINTS"
  | "POST"
  | "THIRD_PILLAR_TRANSFER"
  | "USER_GROUP"
  | "USER"
  | "OWN_USER"
  | "BANNER"
  | "CATEGORY"
  | "DEAL"
  | "PRIVATE_DEAL"
  | "HELP_OPTION"
  | "NEWS"
  | "ORDER"
  | "SOLUTION"
  | "RULE"
  | "IDP"
  | "ROLE";

export type LegacyCurrentUserType = {
  phonePrefix: number;
  phone: string;
  hrNewsletter: boolean;
};

export type LegacySubmitActivationDataType = {
  birth_date: string | null;
  terms_accepted: boolean;
  user: number; // userId
};

export type UpdateCurrentUserResponseType = {
  data: {
    id: number;
    externalId: string | null;
  };
  errors?: {
    property_path: string;
    message: string;
  }[];
  success: boolean;
};

export type UpdateCurrentUserType = {
  email: string;
  recovery_email: string;
  old_password: string;
  plain_password: string;
  newsletter: boolean;
  gender: "m" | "f" | "o";
  phone: string;
  phone_prefix: number;
  address: Partial<{
    street_address: string;
    complement_address?: string;
    city: string;
    country: string;
    postal_code: string;
  }>;
};

export type UserSubscription = {
  subscribed_until: string | null;
  type: UserTypeEnum;
};

export type UserResetPasswordType = {
  token: string;
  newPassword: string;
  confirmPassword: string;
};
