import { syliusApi } from '@swibeco/ecommerce';
import { SubTitle, HoverBehavior } from '@swibeco/ui';
import {
  ECOMMERCE_CATEGORY_ROOT,
  mapProductOrVariantToAnalyticsItem,
  useColorVariant,
} from '@swibeco/shared';
import {
  EnumLinkProps,
  AnalyticsContext,
  AnalyticsEvents,
  TaxonWithProducts,
  UserTypeEnum,
} from '@swibeco/types';
import { MainContentWrapper } from '@swibeco/shared-web';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import TagManager from 'react-gtm-module';
import { usePlatform, selectors as coreSelectors } from '@swibeco/core';
import { useSelector } from 'react-redux';
import { Box } from '@chakra-ui/react';
import { useGetCurrentUser } from '@swibeco/security';
import { ExploreCallouts } from '../ExploreCallouts';
import { PlusBanner } from '../PlusBanner';
import * as Styles from './Products.styles';
import { ProductsSwiper } from './ProductsSwiper';
import { ProductsSkeletonLoading } from './ProductsSkeletonLoading';
import { BrandsSwiper } from './BrandsSwiper';

const Products = () => {
  const { t } = useTranslation();
  const locale = useSelector(coreSelectors.getLocale);
  const { data, isLoading } = useQuery<TaxonWithProducts[]>({
    queryKey: ['explore-discounts', locale],
    queryFn: () => syliusApi.getExploreDiscounts(locale),
  });
  const { data: user } = useGetCurrentUser();
  const { data: brands, isLoading: brandsLoading } = useQuery({
    queryKey: ['brands-sylius', locale],
    queryFn: () =>
      syliusApi.getBrands(undefined, locale, {
        itemsPerPage: 15,
        // isAd: true,
        'channelAvailability[]': UserTypeEnum.PRO.toLowerCase(),
      }),
    // enabled: user?.type === UserTypeEnum.FREE,
  });
  const environment = usePlatform(window);
  const mainColorVariant = useColorVariant('primary', 'main');

  const products = useMemo(
    () =>
      data?.filter((productsList) => productsList.optimizedProducts.length > 0),
    [data]
  );

  const canSplit = products && products.length > 1;

  if (isLoading || brandsLoading) return <ProductsSkeletonLoading />;

  /* analytics */
  const analyticsContext: AnalyticsContext = {
    listId: '',
    listName: '',
    quantity: 1,
    category: '',
  };

  if (data && data.length) {
    data.forEach((dataList, index: number) => {
      if (dataList?.optimizedProducts?.length > 0) {
        const position = index + 1;
        analyticsContext.listId = 'homepage_deals';
        analyticsContext.listName = `Homepage - ${dataList.name}`;
        analyticsContext.category = dataList.name;
        const toAnalytics = mapProductOrVariantToAnalyticsItem(
          analyticsContext,
          locale
        );
        const items = dataList.optimizedProducts.slice(0, 5).map(toAnalytics);

        TagManager.dataLayer({
          dataLayer: {
            event: AnalyticsEvents.VIEW_ITEM_LIST,
            environment,
            position,
            list_name: dataList.name,
            ecommerce: {
              items,
            },
          },
        });
      }
    });
  }
  /* end analytics */

  return (
    <Styles.ProductsSegment data-testid="deals">
      <MainContentWrapper>
        <Styles.Container>
          <SubTitle>{t('core.ecommerce.homepage.deals.title')}</SubTitle>
          <HoverBehavior className="ml-4" color={mainColorVariant}>
            <Styles.Link
              type={EnumLinkProps.NewPage}
              color={mainColorVariant}
              to={`${ECOMMERCE_CATEGORY_ROOT}/all-results`}
            >
              {t('core.ecommerce.homepage.deals.link')}
            </Styles.Link>
          </HoverBehavior>
        </Styles.Container>
        <ExploreCallouts />
        <Box h="32px" />
      </MainContentWrapper>
      {user?.type === UserTypeEnum.FREE && brands && brands.length > 0 && (
        <Box pb="120px">
          <BrandsSwiper
            mainContent
            title={t('core.ecommerce.homepage.brands_for_plus.title')}
            brands={brands}
          />
        </Box>
      )}
      {products?.slice(0, canSplit ? 1 : products.length).map(
        (productsList) =>
          productsList.optimizedProducts.length > 0 && (
            <Box pb="24px" key={productsList.slug}>
              <ProductsSwiper
                mainContent
                title={productsList.name}
                icon={productsList.images?.[0]?.path}
                products={productsList.optimizedProducts}
                analytics={analyticsContext}
              />
            </Box>
          )
      )}
      <PlusBanner />
      {canSplit &&
        products?.slice(1, products.length).map(
          (productsList) =>
            productsList.optimizedProducts.length > 0 && (
              <Box pb="24px" key={productsList.slug}>
                <ProductsSwiper
                  mainContent
                  title={productsList.name}
                  icon={productsList.images?.[0]?.path}
                  products={productsList.optimizedProducts}
                  analytics={{
                    listId: 'homepage_deals',
                    listName: `Homepage - ${productsList.name}`,
                    category: productsList.name,
                    quantity: 1,
                  }}
                />
              </Box>
            )
        )}
    </Styles.ProductsSegment>
  );
};
export default Products;
