/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Navigate } from 'react-router-dom';
import { useUniverseParams } from '@swibeco/ecommerce';

const CategoryPage = React.lazy(
  /* istanbul ignore next */ () => import('../pages/Universe/CategoryPage')
);
const EventPage = React.lazy(
  /* istanbul ignore next */ () => import('../pages/Universe/EventPage')
);
const BrandPage = React.lazy(
  /* istanbul ignore next */ () => import('../pages/Universe/BrandPage')
);
const BrandsPage = React.lazy(
  /* istanbul ignore next */ () => import('../pages/Universe/BrandsPage')
);
const UniversePage = React.lazy(
  /* istanbul ignore next */ () => import('../pages/Universe/UniversePage')
);
const InhouseDealsPage = React.lazy(
  /* istanbul ignore next */ () => import('../pages/Universe/InhouseDealsPage')
);

const UniverseRouter = () => {
  const { context } = useUniverseParams();

  switch (context) {
    case 'category':
      return <CategoryPage />;
    case 'brand':
      return <BrandPage />;
    case 'brands':
      return <BrandsPage />;
    case 'event':
      return <EventPage />;
    case 'universe':
      return <UniversePage />;
    case 'mycompanybenefits':
      return <InhouseDealsPage />;
    default:
      return <Navigate to="/error" />;
  }
};

export default UniverseRouter;
